/**
 * The <Anchor /> component is a standard designed anchor using the brand
 * colors. This exists here primarily because at the time of creation we still
 * had Bootstrap and use this to override Bootstrap styles.
 *
 * Expected usage:
 *
 * ```
 * <Link
 *   href={`/pet?id=${pet.id}`}
 *   passHref
 * >
 *   <Anchor>{pet.name}</Anchor>
 *  </Link>
 * ```
 **/
import styled from 'styled-components';
import { blueLight, blue } from '../theme/colors/brand';

export const Anchor = styled.a<{ disabled?: boolean }>`
  color: ${blueLight};
  transition: color 0.2s;

  :hover,
  :focus,
  :focus-visible {
    color: ${blue};
  }

  [disabled] {
    cursor: not-allowed;
    color: inherit;
  }
  ${({ disabled }) => {
    if (disabled) {
      return `
        cursor: not-allowed;
        color: inherit;
      `;
    }
    return '';
  }}
`;
