import { ComponentProps } from 'react';
import Link, { LinkProps } from 'next/link';
import { Button, Variant, Intent } from 'design-system/components/button';

type Props = ComponentProps<typeof Button> & {
  href: LinkProps['href'];
  label: string | JSX.Element;
  passHref?: LinkProps['passHref'];
};

export function GoToButton({
  href,
  label,
  passHref = false,
  variant = Variant.Outline,
  intent = Intent.Secondary,
}: Props) {
  return (
    <Link href={href} passHref={passHref}>
      <Button variant={variant} intent={intent}>
        <a href={href as string}>{label}</a>
      </Button>
    </Link>
  );
}
