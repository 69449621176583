import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { useEffect } from 'react';
import styled from 'styled-components';

import { LoginForm } from 'page-components/auth/login-form';
import { GoToButton } from 'components/link/go-to-button';
import { Brand } from 'components/images/brand';
import { ContentCard } from 'design-system/components/content-card';
import { userCookieExist } from 'lib/auth';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: ${(p) => p.theme.rem('480px')};
  margin: 10% auto 0;
  padding: ${(p) => `${p.theme.xxl} ${p.theme.md}`};
`;

const AuthHelpContainer = styled.div`
  color: white;
  text-align: center;
  padding: ${(p) => `${p.theme.md} ${p.theme.base}`};
  margin-top: ${(p) => p.theme.lg};

  div {
    margin-bottom: ${(p) => p.theme.spacers.sm};
  }
`;

export function getServerSideProps(
  context: GetServerSidePropsContext
): GetServerSidePropsResult<{}> {
  if (userCookieExist(context.req)) {
    return {
      redirect: {
        statusCode: 302,
        destination: '/',
      },
    };
  }
  return {
    props: {},
  };
}

export default function LoginPage() {
  useEffect(() => {
    global.analytics.page('Login page');
  }, []);

  return (
    <Container>
      <ContentCard>
        <Brand />
        <LoginForm />
      </ContentCard>

      <AuthHelpContainer>
        <div>Don’t have an account?</div>
        <GoToButton href="/signup" label="Sign Up" />
      </AuthHelpContainer>
    </Container>
  );
}
