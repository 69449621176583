import Link from 'next/link';
import { useRouter } from 'next/router';
import { useApolloClient, useMutation } from 'react-apollo';
import { useCallback, useEffect, useState } from 'react';

import { Anchor } from 'design-system/components/anchor';
import { Button } from 'design-system/components/button';
import { HStack, VStack } from 'design-system/components/stack';
import { Input } from 'design-system/components/form';

import { userCurrent } from 'gql/queries';
import { createUpdateUser as createUpdateUserMutation } from 'gql/mutations';
import { setSentryUser } from 'helpers/sentry';
import { saveToken, webAuth } from 'lib/auth';
import { Analytics } from 'lib/analytics';
import { PersonOrganizationPermission, User } from 'types/person';

function getForwardUrl() {
  const params = new URLSearchParams(window.location.search);
  return params.get('forward_url') || '/';
}

// Logging a user in has a few components to it:
// 1. We use Auth0 as an actual identity validator. So users get authorized by auth0
//
// 2. Once they are logged in we have to make sure we have a user for them in our system. We do that by the following logic:
//
// 2.a. First we query for the user with the `userCurrent` query
//      If they exist we then redirect them to the home page (or use the forward url if its set)
//
// 2.b. If the user was authed by Auth0, but we don't have a user for them, we then have to create one.
//      We do this via the `createUpdateUserMutation`
//
export function LoginForm() {
  const [createUpdateUser] = useMutation<{ create_update_user_for_auth: User }>(
    createUpdateUserMutation
  );
  const router = useRouter();
  const client = useApolloClient();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [hasPasswordError, setHasPasswordError] = useState(false);
  const [hasEmailError, setHasEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setEmail(params.get('email') ?? '');
  }, []);

  const onSubmit = useCallback(
    (e) => {
      setError(null);
      e.stopPropagation();
      e.preventDefault();

      // Rough validation. We should change to something like formik to improve this
      if (email.trim().length === 0) {
        setError('Email is required');
        setHasEmailError(true);
        return;
      }

      if (password.length === 0) {
        setError('Password is required');
        setHasPasswordError(true);
        return;
      }

      setIsLoading(true);

      webAuth.client.login(
        {
          realm: 'Username-Password-Authentication',
          username: email,
          password,
        },
        async (error, authResult) => {
          if (error) {
            setError(error.description || error.name || error.error);
            setIsLoading(false);
            return;
          }

          // Store token
          saveToken(authResult);

          ////////////////////////////////////////////////////////////////////////////////////////////////////////////
          // fetch existing user from GraphQL, if for some reason it is doesn't exist create a new one
          // redirect to index as user
          try {
            const res = await client.query<{
              user_current: User;
              user_permissions: PersonOrganizationPermission[];
            }>({
              query: userCurrent,
              fetchPolicy: 'no-cache',
            });

            Analytics.trackUserLoggedIn(
              res.data.user_current,
              res.data.user_permissions
            );
            setSentryUser(email);
            window.Median?.identify(email);

            router.push(getForwardUrl());
          } catch (e) {
            try {
              const res = await createUpdateUser();

              if (!!res.data?.create_update_user_for_auth.id) {
                router.push(getForwardUrl());
              }
            } catch {
              setError('Something went wrong. Please try logging in again.');
              setIsLoading(false);
            }
          }
        }
      );
    },
    [router, createUpdateUser, email, password, client]
  );

  return (
    <VStack as="form" onSubmit={onSubmit}>
      {!!error && <p>{error}</p>}

      <Input
        // @ts-ignore
        $error={hasEmailError}
        disabled={isLoading}
        required
        autoFocus
        type="email"
        aria-label="Email"
        name="email"
        placeholder="example@email.com"
        autoComplete="username"
        value={email}
        onChange={(event) => {
          setError(null);
          setHasEmailError(false);
          setEmail(event.currentTarget.value);
        }}
      />

      <Input
        // @ts-ignore
        $error={hasPasswordError}
        disabled={isLoading}
        required
        aria-label="Password"
        type="password"
        name="password"
        placeholder="password"
        autoComplete="current-password"
        data-median="exclude"
        value={password}
        onChange={(event) => {
          setError(null);
          setHasPasswordError(false);
          setPassword(event.currentTarget.value);
        }}
      />

      <HStack justify-content="flex-end" align-items="center">
        <Link href="/reset-password" passHref>
          <Anchor style={{ fontSize: '0.8rem' }}> Forgot Password?</Anchor>
        </Link>

        <Button disabled={isLoading} type="submit">
          Log in
        </Button>
      </HStack>
    </VStack>
  );
}
